import anime from "animejs/lib/anime.es";
import fromEntries from "fromentries";
import { TNanoPopupConfig } from "../types/TNanoPopupConfig";
import "../../../resources/less/Troubadour/components/Nanopopup.less";
import NanoPopupShownEvent from "./Events/NanoPopupShownEvent";
import NanoPopupHiddenEvent from "./Events/NanoPopupHiddenEvent";

export default class NanoPopup {
  protected closeContainer: JQuery<HTMLElement>;
  private readonly _actionButton: JQuery<HTMLButtonElement>;
  private readonly animes: anime[];
  private buttonGroup: JQuery<HTMLElement>;
  private readonly actionButtonText: string;
  private readonly cancelButton: JQuery<HTMLButtonElement>;
  private readonly inputPopup: JQuery<HTMLInputElement>;
  private confirmRow: JQuery<HTMLInputElement>;
  private confirmCb: JQuery<HTMLInputElement>;
  private readonly closeButtonGroup: JQuery<HTMLButtonElement>;
  private readonly _currentTarget: JQuery<HTMLElement>;

  constructor(config: TNanoPopupConfig) {
    this.animes = [];
    this._config = config;

    if (this._config.target.length <= 0) {
      throw new Error(`Can't find element of ${this._config.target}`);
    }

    this._currentTarget = $(this._config.target);

    this._currentTarget.addClass("no-event");
    this.buttonGroup = this._currentTarget.find(".button-group");
    this._actionButton = this._currentTarget.find(
      ".action-button",
    ) as JQuery<HTMLButtonElement>;
    this.actionButtonText = this.actionButton.text();
    this.cancelButton = this._currentTarget.find(
      ".cancel-button",
    ) as JQuery<HTMLButtonElement>;
    this.closeContainer = this._currentTarget.find(".close-container");
    this.inputPopup = this._currentTarget.find(
      ".input-popup input",
    ) as JQuery<HTMLInputElement>;
    this.confirmRow = this._currentTarget.find(
      ".popup-confirm-row",
    ) as JQuery<HTMLInputElement>;
    this.confirmCb = this._currentTarget.find(
      ".popup-checkbox",
    ) as JQuery<HTMLInputElement>;

    this.closeButtonGroup = this._currentTarget.find(
      ".close-button, #close-button",
    ) as JQuery<HTMLButtonElement>;

    this.closeButtonGroup.on("click", this.closeButtonGroup, () => {
      this.Hide();
    });

    this.actionButton.on("click", () => {
      if (!this.actionButton.hasClass("disabled")) {
        this.actionButton.addClass("disabled");
        if (this._config.onAction) {
          this._config.onAction();
        }
      }
    });

    this.cancelButton.on("click", () => {
      if (this._config.onCancel) {
        this._config.onCancel();
      }
      if (this._config.onClose) {
        this._config.onClose();
      }
    });

    this.confirmRow.on("keyup keydown change", () => {
      this.CheckValidity();
    });

    this.closeContainer.on("click", () => {
      this.Hide();
    });
  }

  private _config: TNanoPopupConfig;

  public get config(): TNanoPopupConfig {
    return this._config;
  }

  private _backPanel: JQuery<HTMLDivElement>;

  public get backPanel(): JQuery<HTMLDivElement> {
    return this._backPanel;
  }

  private _isDisplayed = false;

  public get isDisplayed(): boolean {
    return this._isDisplayed;
  }

  public get actionButton(): JQuery<HTMLButtonElement> {
    return this._actionButton;
  }

  public get currentTarget(): JQuery<HTMLElement> {
    return this._currentTarget;
  }

  // Depending on the input from the twig template, si will return needed value
  // Input text -> return text
  // Input Pattern -> bool

  // Input checkbox -> bool
  public GetInputValue() {
    if (this.inputPopup.length <= 0) {
      throw new Error("Can't find any input...");
    }
    const inputValue = [];

    this.inputPopup.each((i, e) => {
      if ($(e).attr("pattern")) {
        let InputPattern: boolean;
        if ($(e).val() === "") {
          InputPattern = false;
        } else
          InputPattern = !(
            $(e)[0].validity.patternMismatch || $(e)[0].validity.valueMissing
          );
        inputValue.push(["InputPattern", InputPattern]);
      } else if ($(e).attr("type") === "text") {
        inputValue.push(["inputText", $(e).val()]);
      } else if ($(e).attr("type") === "checkbox") {
        inputValue.push(["inputCheckbox", $(e).is(":checked")]);
      }
    });

    return fromEntries(inputValue);
  }

  // Return an array of the current input used for that popup
  public GetInput() {
    if (this.inputPopup.length <= 0) {
      throw new Error("Can't find any input...");
    }
    const inputValue = [];
    this.inputPopup.each((i, e) => {
      if ($(e).attr("pattern")) {
        inputValue.push(["InputPattern", e]);
      } else if ($(e).attr("type") === "text") {
        inputValue.push(["inputText", e]);
      } else if ($(e).attr("type") === "checkbox") {
        inputValue.push(["inputCheckbox", e]);
      }
    });

    const obj = fromEntries(inputValue);
    if (inputValue.length <= 1) {
      return Object.values(obj)[0];
    }
    return obj;
  }

  public Show(): void {
    if (anime.running.length > 1 && this._backPanel !== undefined) {
      return;
    }

    window.dispatchEvent(new NanoPopupShownEvent(this));

    if (this._config.willOpen) {
      this._config.willOpen();
    }
    this.generateBackPanel();
    this._currentTarget.addClass("display").removeAttr("style");
    // Disable events during the animation
    const timer = setTimeout(() => {
      this._currentTarget.removeClass("no-event");
      clearTimeout(timer);
    }, 500);
    if (this.inputPopup[0] !== undefined) {
      this.CheckValidity();
    }
    if (this._config.isOpen) {
      this._config.isOpen();
    }
    this.closeButtonGroup.show();
    this._isDisplayed = true;
  }

  public Hide(
    doFadeOut = false,
    doResetButton = true,
    fromPaywall = false,
  ): void {
    this.stopAnimations();
    // It can happen on page with multiple popups that we call a popup.
    // Hide even if the popup never show up in the first place
    if (this._backPanel === undefined) {
      return;
    }
    window.dispatchEvent(new NanoPopupHiddenEvent(this, fromPaywall));

    if (doFadeOut) {
      this._currentTarget.fadeOut("slow", () => {
        this._currentTarget.removeClass("display");
      });
    } else {
      this._currentTarget.removeClass("display");
    }

    if (doResetButton) {
      this.resetButton();
    }

    this._currentTarget.siblings(".nanopopup-backpanel").remove();
    this._currentTarget.addClass("no-event");
    if (this._config.isHidden) {
      this._config.isHidden();
    }
    if (this._config.onHide) {
      this._config.onHide();
    }
    this._isDisplayed = false;
  }

  public CheckValidity(): void {
    let isValid = true;
    const inputValues = this.GetInputValue();
    if (inputValues.InputPattern !== undefined && !inputValues.InputPattern) {
      isValid = false;
    }
    if (inputValues.inputCheckbox !== undefined && !inputValues.inputCheckbox) {
      isValid = false;
    }
    if (isValid) {
      this.actionButton.removeClass("disabled");
    } else {
      this.actionButton.addClass("disabled");
    }
  }

  public Waiting(): void {
    this.actionButton.addClass("disabled");
    this.loadIcon("fas fa-circle-notch fa-spin");
  }

  public Success(): void {
    this.Hide();
    this.Reset();
  }

  public Error(): void {
    this.actionButton.removeClass("disabled");
    this.loadIcon("fas fa-times");
    this.actionButton.addClass(" action-button button-error");
  }

  public Reset(): void {
    this.CheckValidity();
    this.actionButton.html(this.actionButtonText);
    this.actionButton.removeClass("button-error button-success");
  }

  protected resetButton() {
    if (this.actionButton.find(".button-text").length != 0) {
      this.actionButton.find(".button-text").text(this.actionButtonText);
    } else {
      this.actionButton.html(this.actionButtonText);
    }
    this.actionButton.removeClass("disabled");

    if (this.buttonGroup.length > 0) {
      const btn = this.buttonGroup[0].getElementsByTagName("button");
      for (let i = 0; i < btn.length; i += 1) {
        btn[i].removeAttribute("style");
        btn[i].classList.remove(
          "button-disable",
          "button-error",
          "button-success",
        );
        anime.remove(btn[i]);
        btn[i].style.opacity = "1";
      }
    }
  }

  private generateBackPanel(): void {
    if (this._currentTarget.siblings(".nanopopup-backpanel").length === 0) {
      const backPanel = document.createElement("div");
      this._currentTarget.after(backPanel);
      this._backPanel = $(backPanel);
      this._backPanel.addClass("nanopopup-backpanel display");
    } else {
      this._backPanel = this._currentTarget.siblings(
        ".nanopopup-backpanel",
      ) as JQuery<HTMLDivElement>;
    }

    if (!this._config.disabledBackPanel) {
      this._backPanel.on("click", () => {
        if (this._config.onClose) {
          this._config.onClose();
        }
        this.Hide();
      });
    }
  }

  // This will generate the className icon
  private loadIcon(className): void {
    const ico = document.createElement("i");
    ico.className = `${className} inner-icon`;
    ico.style.top = "25px";
    this.actionButton.html("");
    this.actionButton.append(ico);
    const icoAnimation = anime.timeline({
      easing: "easeInOutCubic",
      duration: this._config.duration,
      delay: 0,
      targets: ico,
      opacity: "1",
    });
    icoAnimation.add({
      top: "0",
    });
    this.animes.push(ico);
  }

  private stopAnimations(): void {
    for (let i = 0; i < this.animes.length; i += 1) {
      $(this.animes[i]).stop();
      anime.remove(this.animes[i]);
    }
  }
}
